<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div id="body-content-area" style="max-width: 100%; margin: auto; overflow: hidden;">
      <div v-if="isLoading">
        <Loading/>
      </div>
      <div :class="{ 'visible': isLoading }">
        <div id="buttongroup" class="bottom-bar" style="margin-bottom: 1rem; display: flex; justify-content: flex-end;">
            <div class="level">
              <div class="level-right">
                  <div class="field is-grouped">
                    <div v-if="selected.length === 0" class="control">
                        <a :class="['button is-accent', $hasPermissions(clientSession, ['DOCUMENT_CENTER'], 2) ? '' : 'disabled']">
                          <span class="icon">
                            <i class="fal fa-plus-square"></i>
                          </span>
                          <span>
                              <router-link :to="{ name: !$hasPermissions(clientSession, ['DOCUMENT_CENTER'], 2) ? '' : 'DocumentCenterCreateScreen' }" class="has-text-white">
                                Add Document
                              </router-link>
                          </span>
                        </a>
                    </div>
                  </div>
              </div>
            </div>
        </div>
        <Grid
            :style="{maxHeight: maxHeight + 'px', height: maxHeight + 'px'}"
            :data-items="documents"
            :filters="filter"
            :sortable="true"
            :sort="sort"
            :filterable="true"
            :columns="staticColumns"
            @filterchange="filterChangeHandler"
            :pageable="{
              ...pageable,
              pageSizes: pageSizes
            }"
            :skip="skip"
            :take="take"
            :total="totalDocuments"
            @sortchange="sortchange"
            @pagechange="pagechange">
            <template v-slot:SizeCellHeader="{props}">
                <td>
                  <router-link class="bcc-color" :to="{ name: 'EditInstitution', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}`, skip: `${skip}` }}">
                    {{props.dataItem.name}}
                  </router-link>
                </td>
              </template>
            <template v-slot:NameCell="{props}">
                <td>
                  <router-link class="bcc-color" :to="{ name: 'DocumentsCenterEditScreen', params: { id: props.dataItem.id, document: props.dataItem }}">
                    <span class="">{{ props.dataItem.displayName }}</span>
                  </router-link>
                </td>
            </template>

            <template v-slot:GroupCell="{props}">
                <td>
                  {{ getCarrierCode(props.dataItem) }}
                </td>
            </template>

            <template v-slot:TypeCell="{props}">
                <td>
                {{props.dataItem.typeCode.replace('.', '').toUpperCase()}}
                </td>
            </template>

            <template v-slot:SizeCell="{props}">
                <td style="text-align: right;">
                {{ getFileSize(props.dataItem.fileSize) }}
                </td>
            </template>

            <template v-slot:UploadDateCell="{props}">
                <td>
                {{ getUTCDate(props.dataItem.uploadDate) }}
                </td>
            </template>

            <template v-slot:UploadedByCell="{props}">
                <td>
                {{ props.dataItem.uploadUserName }}
                </td>
            </template>

            <template v-slot:ActionCell="{props}">
                <td style="width: 100%; padding: 0;">
                <div class="action-cell">
                  <div v-tooltip.left-start="{ content: 'Download Document', container: false, classes: ['tooltip'] }">
                    <a :href="getDocumentPath(props.dataItem)" download><i class="fa fa-download"></i></a>
                  </div>
                  <div v-tooltip.left-start="{ content: 'View Document', container: false, classes: ['tooltip'] }">
                    <a href="" @click.prevent="viewDocument(props.dataItem)"><i class="fa fa-eye"></i></a>
                  </div>
                  <div v-tooltip.left-start="{ content: 'Delete Document', container: false, classes: ['tooltip'] }">
                    <a :class="['trash', !$hasPermissions(clientSession, ['DOCUMENT_CENTER'], 2) ? 'disabled' : '']" href="" @click.prevent="!$hasPermissions(clientSession, ['DOCUMENT_CENTER'], 2) ? null : promptDelete(props.dataItem)"><i class="fa fa-trash"></i></a>
                  </div>
                </div>
                </td>
            </template>

            <template v-slot:reportTypeFilterSlotTemplate="{props}">
                <div class="k-filtercell">
                  <div class="k-filtercell-wrapper">
                    <input type="text" class="k-textbox" @blur="filterChangeHandler" @keyup="filterChangeHandler" :id="`${props.field}`" v-model="filters[props.field]">
                  </div>
                </div>
              </template>
        </Grid>
      </div>
    </div>

    <!-- Delete modal: // TODO [BD|05-2021] Wire this up to prompt the user to delete the items -->
    <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
        <div class="modal-background" @click="showDeleteModal = false"></div>
        <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <div class="modal-card-title has-bg-danger">Confirm Delete</div>
            <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
        </header>
        <section class="modal-card-body">
            <div class="content">
            Type "<b>delete</b>" to confirm that you want to delete "{{ selectedFile.name }}"
            </div>
            <div class="field">
            <div class="control">
                <input
                type="text"
                class="input"
                placeholder="type `delete` and then click confirm"
                v-focus
                v-model.trim="confirmationText"
                @keydown.enter.prevent="deleteIfConfirmed"
                />
            </div>
            </div>
        </section>
        <footer class="modal-card-foot">
            <a
            class="button is-danger"
            @click="deleteIfConfirmed"
            :disabled="confirmationText !== 'delete'"
            >
            <span class="icon">
                <i class="fal fa-trash-alt"></i>
            </span>
            <span>Confirm</span>
            </a>
            <a class="button" @click="showDeleteModal = false">Cancel</a>
        </footer>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Grid } from '@progress/kendo-vue-grid'
import moment from 'moment-timezone'
import Loading from './Loading.vue'

function debounce (callback, duration = 0) {
  let timer = null

  return function (...args) {
    clearTimeout(timer)
    timer = null

    timer = setTimeout(callback.bind(this, ...args), duration)
  }
}

export default {
  name: 'SearchScreen',
  props: {
    query: String
  },
  components: {
    'Grid': Grid,
    Loading
  },
  data () {
    return {
      maxHeight: 0,
      minResults: 0,
      pageheading: 'Documents Center',
      documents: null,
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      confirmationText: '',
      selected: [],
      skip: 0,
      totalDocuments: 0,
      carriers: [],
      take: 20,
      pageSize: 20,
      isLoading: true,
      perPage: 20,
      page: 1,
      showDeleteModal: false,
      selectedFile: null,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'name',
          title: 'Document Name',
          cell: 'NameCell',
          hidden: false,
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'carrierCode',
          title: 'Group',
          width: '100px',
          cell: 'GroupCell',
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'typeCode',
          title: 'Type',
          width: '100px',
          cell: 'TypeCell',
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'fileSize',
          title: 'Size',
          cell: 'SizeCell',
          width: '75px',
          className: 'text-right',
          filterable: false
        },
        {
          field: 'uploadDate',
          title: 'Uploaded On',
          cell: 'UploadDateCell',
          width: '130px',
          filterable: false,
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          field: 'UploadUserName',
          title: 'Uploaded By',
          cell: 'UploadedByCell',
          width: '200px',
          filterCell: 'reportTypeFilterSlotTemplate'
        },
        {
          cell: 'ActionCell',
          title: 'Actions',
          width: '150px',
          filterable: false
        }
      ],
      sort: [
        { field: 'name', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: []
      },
      filters: {
        name: '',
        carrierCode: '',
        typeCode: '',
        uploadDate: '',
        uploadUserName: ''
      }
    }
  },
  computed: {
    ...mapState(['clientSession', 'activeSite']),
    pageTitle () {
      return (this.pageheading + ' - ' + this.activeSite.displayName)
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalDocuments
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  mounted () {
    let that = this

    if (localStorage.documentFilters) { this.filters = JSON.parse(localStorage.getItem('documentFilters')) }

    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.bottom-bar'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults + 2
        this.perPage = numberOfResults + 2
        this.minResults = numberOfResults + 2
        this.maxHeight = maxHeight

        this.getCarriers()
        this.getDocuments()
      }

      that.getListHeights('body-content-area', 40, 49, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 49, elements, func.bind(that))
      })
    })
  },
  methods: {
    async getCarriers () {
      let response = await this.axios.get(`carriers?perPage=1000&page=1&sortBy=name`)

      if (response.status >= 200 && response.status < 300) {
        this.carriers = response.data.records
      }
    },
    async getDocuments () {
      let filterString = ``
      const filters = {
        name: 'name',
        carrierCode: 'carrierCode',
        typeCode: 'typeCode',
        uploadDate: 'uploadDate',
        UploadUserName: 'UploadUserName'
      }

      for (const value in this.filters) {
        if (this.filters[value] !== '' && !['inArray', 'replaceOrPush', '0'].includes(value)) {
          filterString += `&${filters[value]}=${value === 'uploadDate' ? moment(this.filters[value]).toISOString() : this.filters[value]}`
        }
      }

      let response = await this.axios.get(`documents?perPage=${this.take}&page=${(this.skip / this.take) + 1}&sortBy=${this.sort[0].field}&sortDirection=${this.sort[0].dir}&isCarrierDocument=True${filterString}`)

      if (response.status === 200) {
        this.totalDocuments = response.data.page.totalRecords
        this.documents = response.data.records
      }

      this.isLoading = false
    },
    getCarrierCode (document) {
      let carrier = null

      this.carriers.forEach(function (_carrier) {
        if (parseInt(_carrier.id) === parseInt(document.carrierId)) {
          carrier = _carrier
        }
      })

      return carrier ? carrier.code : 'User Document'
    },
    promptDelete (document) {
      this.showDeleteModal = true
      this.selectedFile = document
    },
    async deleteIfConfirmed () {
      if (this.confirmationText.toLowerCase() === 'delete') {
        let response = await this.axios.delete('documents/' + this.selectedFile.id)

        if (response.status >= 200 && response.status < 300) {
          this.showDeleteModal = false
          this.getDocuments()
        }

        this.confirmationText = ''
      }
    },
    clearFilters: function () {
      // Manually clear filter values
      if (this.filters) {
        this.filters.forEach(element => {
          var filterInput = document.getElementById(element.field)
          if (filterInput) { filterInput.value = '' }
        })

        this.filters = []
      }

      this.skip = 0
      this.sort = [
        { field: 'uploadDate', dir: 'asc' }
      ]

      localStorage.removeItem('documentFilters')
      localStorage.removeItem('documentSort')

      this.getReports(false)
    },
    filterChangeHandler () {
      debounce(() => {
        localStorage.setItem('documentFilters', JSON.stringify(this.filters))

        this.skip = 0
        this.getDocuments(false)
      }, 750)()
    },
    viewDocument (document) {
      this.$router.push({ name: 'ViewDocumentScreen', params: { id: document.id, document: document } })
    },
    sortchange (e) {
      this.sort = e.sort
      this.getDocuments()
    },
    pagechange (e) {
      this.take = e.page.take
      this.skip = e.page.skip

      this.getDocuments()
    },
    handleCheckEvent (e, document) {
      if (e.isChecked) {
        this.selected.push(document)
      } else {
        this.selected = this.selected.filter(function (doc) {
          if (doc.name !== document.name) {
            return doc
          }
        })
      }
    },
    getUTCDate (uploadDate) {
      return moment(uploadDate).utc().format('MM/DD/YYYY')
      // return new Date(uploadDate).toDateString()
    }
  }
}
</script>

<style scoped>
  .k-grid tbody tr {
    cursor: pointer!important;
  }

  .action-cell {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 25px;
  }

  @media (min-width: 800px) {
  td .action-cell a {
    /* display: none; */
    color: #97c666!important;
    margin: 0 .25rem;
  }
  }

  tr:hover .action-cell a {
    display: inline-block;
  }

  .bcc-color {
    color: #7fb942;
  }

  .k-master-row > td:first-child {
    width: auto !important;
    text-align: left !important;
    justify-content: start !important;
    padding-left: .5rem !important;
  }

  .trash i {
    color: #97c666;
  }

  .hidden {
    display: none;
  }

  .k-link {
        color: #595959!important;
    }
  .tooltip {
    font-size: 12px!important;
    padding: 5px!important;
  }

  .visible {
    visibility: hidden !important;
  }

  #body-content-area {
    position: absolute;
    top: 35px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    overflow-y: auto;
  }
</style>
